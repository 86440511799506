import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src750413537/src/src/layout/defaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const Note = makeShortcode("Note");
const Stack = makeShortcode("Stack");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Section mdxType="Section">
      <h1 {...{
        "id": "deployments"
      }}>{`Deployments`}</h1>
      <p>{`Deployments (or releases) may involve code changes at any and all levels of the codebase.`}</p>
      <p>{`In terms of a full code deployment, all packages must be released. In practical application, a change might include changes specific to the CMS, the core components or anywhere in between.`}</p>
      <p>{`For documentation purposes, we'll use a low-level dependency update as the catalyst for a release.`}</p>
      <strong>
  Use the following template as a guide. Code changes must be propagated to
  every subsequent repo in the following list:
      </strong>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#rebel-web-components"
          }}>{`rebel-web-components`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#rebel-web-ui"
          }}>{`rebel-web-ui`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#rebel-web-cms"
          }}>{`rebel-web-cms`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#rebel-web-src"
          }}>{`rebel-web-src`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#codebuild-and-codepipeline"
          }}>{`CodeBuild and CodePipeline`}</a></li>
      </ul>
    </Section>
    <Section mdxType="Section">
      <h2 {...{
        "id": "packages"
      }}>{`Packages`}</h2>
      <p>{`The first few sections deal with the release of installable NPM packages. These packages can be released at any time and have no effect of customer facing features until the platform in which they're installed is deployed.`}</p>
    </Section>
    <Section mdxType="Section">
      <h3 {...{
        "id": "rebel-web-components"
      }}>{`rebel-web-components`}</h3>
      <p>{`The @rebeldotcom/components package has no other Rebel-specific dependencies. Releases typically include a bump in external package versions, modifications in existing components or new components.`}</p>
      <p>{`Once a PR is merged and the `}<em parentName="p">{`main`}</em>{` branch is up to date and pushed to github, a new package can be published.`}</p>
      <p>{`Using `}<a parentName="p" {...{
          "href": "https://semver.org/"
        }}>{`semantic versioning`}</a>{`, there are three different deployment scripts: `}<em parentName="p">{`Major`}</em>{`, `}<em parentName="p">{`minor`}</em>{` and `}<em parentName="p">{`patch`}</em>{`:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-sh"
        }}>{`npm run deploy:patch
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-sh"
        }}>{`npm run deploy:minor
`}</code></pre>
      <p>{`The major release script is not implemented at this point (we don't have an official v1 yet), but should follow this format.`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-sh"
        }}>{`npm run deploy:major
`}</code></pre>
      <Note mdxType="Note">
  *Note: Publishing new versions of @rebeldotcom/components and @rebeldotcom/ui
  automatically bumps the version number in the package.json file and pushes to
  the main branch. Admin/Owner rights on the repo are necessary to do this. The
  same access rights should also be present on the package at
  [npmjs](https://www.npmjs.com/)
      </Note>
      <p>{`Once the release is done, the new version number is shown in the terminal. This is the new version to install into subsequent packages/platforms.`}</p>
    </Section>
    <Section mdxType="Section">
      <h3 {...{
        "id": "rebel-web-ui"
      }}>{`rebel-web-ui`}</h3>
      <p>{`Following the same set of scripts, the @rebeldotcom/ui package can be published by anyone with Admin/Owner rights on the repository.`}</p>
      <p>{`As mentioned in the frontend `}<a parentName="p" {...{
          "href": "/frontend/overview#dependency-notes"
        }}>{`overview`}</a>{`, rebel-web-ui has a peer dependency of @rebeldotcom/components. If the code changes include a new version of the components package, the new version should be updated in both devDependencies and peerDependecies in the `}<a parentName="p" {...{
          "href": "https://github.com/rebeldotcom/rebel-web-ui/blob/main/package.json"
        }}>{`package.json`}</a>{` file.`}</p>
    </Section>
    <Section mdxType="Section">
      <h2 {...{
        "id": "platforms"
      }}>{`Platforms`}</h2>
      <p>{`There are currently two platforms (excluding this documentation service). The first is Rebel's modern CMS platform built in GatsbyJS and the second contains older React components to be run on RebelWeb(IIS)`}</p>
    </Section>
    <Section mdxType="Section">
      <Stack mb={4} mdxType="Stack">
        <h3 {...{
          "id": "rebel-web-cms"
        }}>{`rebel-web-cms`}</h3>
        <p>{`With the assumption that the components and/or ui packages have been released, they now have to be installed into the CMS project. This is done by modifying the `}<a parentName="p" {...{
            "href": "https://github.com/rebeldotcom/rebel-web-cms/blob/main/package.json"
          }}>{`package.json`}</a>{` file to include the new versions and running"`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-sh"
          }}>{`npm install
`}</code></pre>
        <p>{`Once this is done, a quick sanity check is recommended. Running locally is great for a quick visual check, but a build and serve performs a strict check and may highlight issues. These may go unnoticed when running locally and stop a build further in the deployment process.`}</p>
        <p>{`Running locally:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-sh"
          }}>{`npm start
`}</code></pre>
        <p>{`Building and serving:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-sh"
          }}>{`npm run build:serve
`}</code></pre>
      </Stack>
      <h3 {...{
        "id": "rebel-web-src"
      }}>{`rebel-web-src`}</h3>
      <p>{`Similar to the rebel-web-cms project, both @rebeldotcom/components and @rebeldotcom/ui should stay up to date in the rebel-web-src project.`}</p>
      <p>{`The following script is used to build the rebel-web-src bundles for local development:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-sh"
        }}>{`npm run build:local
`}</code></pre>
      <p>{`Differing from the local setup of the CMS, the rebel-web-src project is reliant on `}<a parentName="p" {...{
          "href": "https://github.com/rebeldotcom/rebelweb"
        }}>{`RebelWeb`}</a>{` running on IIS.`}</p>
      <p>{`Running the script above builds the bundles in the /dist folder of the application. A virtual folder should be created in IIS to map from the RebelWeb project to the rebel-web-src /dist folder containing the build.`}</p>
    </Section>
    <Section mdxType="Section">
      <h2 {...{
        "id": "codebuild-and-codepipeline"
      }}>{`CodeBuild and CodePipeline`}</h2>
      <p>{`Both rebel-web-cms and rebel-web-src use CodeBuild and CodePipeline for deployments. The configuration for each is in their respective /terraform folders.`}</p>
      <p>{`rebel-web-cms creates three different`}</p>
    </Section>
    <Section mdxType="Section">
      <h2 {...{
        "id": "cloudfront"
      }}>{`CloudFront`}</h2>
      <p>{`As we migrate pages over from IIS to GatsbyJS and need to direct traffic to the appropriate locations, we use AWS CloudFront behaviours at the page level.`}</p>
      <p>{`As RebelWeb has/had the majority of routes, the initial idea is to create individual routes/behaviours within CloudFront for each page created within rebel-web-cms. Further, a rewrite rule must exist to support "/" and "/index.html" for the various pages.`}</p>
      <p>{`The current steps necessary for a new CMS page to be released to production are as follows:`}</p>
      <Stack mb={4} mdxType="Stack">
        <h4 {...{
          "id": "1-create-a-new-cloudfront-behaviour-in-rebel-web-cms"
        }}>{`1. Create a new `}<a parentName="h4" {...{
            "href": "https://github.com/rebeldotcom/rebel-web-cms/blob/main/terraform/modules/rebel-web-cms-pipeline/cloudfront.tf"
          }}>{`CloudFront behaviour`}</a>{` in rebel-web-cms`}</h4>
        <p>{`The cloudfront.tf can be found at `}<a parentName="p" {...{
            "href": "https://github.com/rebeldotcom/rebel-web-cms/blob/main/terraform/modules/rebel-web-cms-pipeline/cloudfront.tf"
          }}>{`/terraform/modules/rebel-web-cms-pipeline/cloudfront.tf`}</a></p>
        <p>{`All behaviours follow the same basic template:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`ordered_cache_behavior {
    path_pattern    = "/managed-wordpress*"
    allowed_methods = ["GET", "HEAD"]
    cached_methods  = ["GET", "HEAD"]
    target_origin_id = "s3"

    forwarded_values {
      query_string = false
      cookies {
        forward = "none"
      }
    }

    min_ttl                = 86400
    default_ttl            = 86400
    max_ttl                = 31536000
    compress               = true
    viewer_protocol_policy = "redirect-to-https"

    lambda_function_association {
      event_type = "origin-request"
      lambda_arn = "\${lookup(var.cloudfront_lambda, var.environment)}:\${local.lambda_version}"
    }

    lambda_function_association {
      event_type = "viewer-response"
      lambda_arn = "\${lookup(var.cloudfront_lambda, var.environment)}:\${local.lambda_version}"
    }

    lambda_function_association {
      event_type = "viewer-request"
      lambda_arn = "\${lookup(var.cloudfront_lambda, var.environment)}:\${local.lambda_version}"
    }
  }
`}</code></pre>
        <Note mdxType="Note">
  *Note: AWS has a limit for CF behaviours. Over time, we may need to request
  increases to this limit as the number grows.
        </Note>
      </Stack>
      <Stack mb={4} mdxType="Stack">
        <h4 {...{
          "id": "2-run-the-terraform-deply-script-for-the-respective-environments"
        }}>{`2. Run the terraform deply script for the respective environments`}</h4>
        <p>{`There is a single script used to update the deployment infrastructure with a few args to allow for various options. The mandatory arg refers to the environment (AWS Account) to which the infrastructure is being deployed.`}</p>
        <Stack mb={3} mdxType="Stack">
          <strong>dev (Development):</strong>
          <pre><code parentName="pre" {...{
              "className": "language-sh"
            }}>{`cd ./terraform
./deploy.sh dev
`}</code></pre>
          <strong>master (Production):</strong>
          <pre><code parentName="pre" {...{
              "className": "language-sh"
            }}>{`cd ./terraform
./deploy.sh master
`}</code></pre>
        </Stack>
        <h5 {...{
          "id": "a-note-on-branches"
        }}>{`A Note on Branches`}</h5>
        <p>{`Deploying terraform not only changes the CloudFront infrastructure, but CodePipline, CodeBuild, etc... as well.`}</p>
        <p>{`The default branch used for the pipelines is always `}<strong>{`main`}</strong>{`. In the event that a specific branch should be used for the pipeline, a second arg can be used:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-sh"
          }}>{`./deploy.sh dev my-branch
`}</code></pre>
        <Note mdxType="Note">
  <Stack mb={2} mdxType="Stack">
    *Note: CodeBuilds and CodePipelines are different things. A Build is created
    when the Pipeline runs. By deploying the changes to terraform as described
    in this seciton, we are NOT deploying code but simply tearing down and
    rebuilding the CodePipeline itself.
  </Stack>
  <Stack mdxType="Stack">
    By redeploying the Pipeline with a different branch, we're simply changing
    the infrastructure to watch for changes on that specific branch to trigger
    the pipeline.
  </Stack>
        </Note>
      </Stack>
      <Stack mb={4} mdxType="Stack">
        <h4 {...{
          "id": "3-merge-a-pr-in-the-rewrites-repository-including-the-rewrite-rule"
        }}>{`3. Merge a PR in the rewrites repository including the rewrite rule`}</h4>
        <p>{`Now that the code changes have been deployed to the cloud and CloudFront has been modifying to support the correct routing, a new page should be accessible via its direct path (ex: rebel.com/new-page/index.html)`}</p>
        <p>{`In order to support the path without explicitly adding /index.html to the address, a rewrite rule must be added to `}<a parentName="p" {...{
            "href": "https://github.com/rebeldotcom/rw-web-cloudfront-rewrite-redirects/blob/master/src/redirects.json"
          }}>{`rw-web-cloudfront-rewrite-redirects`}</a></p>
        <Note mdxType="Note">
  *Note: The rewrite rule must be added to both reseller definitions. "internic"
  and "rebel"
        </Note>
        <p>{`See the current configurations for examples.`}</p>
        <p>{`Once a PR in this repo is merged to the main branch, a CodePipeline is automatically triggered for both Production and Development.`}</p>
        <p>{`At this point, the new slug should be accessible in the respective environments.`}</p>
      </Stack>
      <Stack mb={4} mdxType="Stack">
        <h4 {...{
          "id": "4-sanity-check-always-sanity-check"
        }}>{`4. Sanity check. Always sanity check!`}</h4>
        <p>{`Seriously... just check your stuff.`}</p>
      </Stack>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      